import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PropertiesContext } from "../context/Properties";
import ImgGallery from "../components/ImgGallery";
import Map from "../components/Map";
import { FaDoorOpen, FaToilet, FaUsers } from "react-icons/fa";
import Calendar from "react-calendar";
import { fetchCalendar } from "../Utils/Helper";
import Spinner from "../components/Spinner";
import { formatDate, getDatesInRange } from "../Utils/Helper";
import BookingPopup from "../components/BookingPopup";
import { BookingContext } from "../context/Booking";

function PropertyDetails() {
  const { booking, updateBooking } = useContext(BookingContext);
  const { nickname } = useParams();
  const { properties, getPropertyByNickname, getRulesFirebase } = useContext(PropertiesContext);
  const [property, setProperty] = useState(null);
  const [calendarLoading, setCalendarLoading] = useState(true);

  const [disabledDates, setDisabledDates] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [rules, setRules] = useState(null);
  const [rulesApproved, setRulesApproved] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const [enableButton, setEnableButton] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [showAllAmenities, setShowAllAmenities] = useState(false);

  useEffect(() => {
    if (properties.length > 0) {
      fetchProperty(nickname);
      fetchRules(nickname);
    }
  }, [properties]);

  useEffect(() => {
    if (selectedDates.length > 0) {
      verifySelectedDates();
    }
  }, [selectedDates]);

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [showPopup]);

  const fetchProperty = async (nickname) => {
    const foundProperty = getPropertyByNickname(nickname);
    setProperty(foundProperty);

    if (foundProperty) {
      setCalendarLoading(true);
      try {
        const { disabledDates, availableDates } = await fetchCalendar(foundProperty._id);
        setDisabledDates(disabledDates);
        setAvailableDates(availableDates);
      } catch (error) {
        console.error("Error fetching calendar:", error);
      } finally {
        setCalendarLoading(false);
      }
    }
  };

  const fetchRules = async (propertyName) => {
    try {
      const rulesData = await getRulesFirebase(propertyName);
      if (rulesData) {
        setRules(rulesData);
      } else {
        alert("No rules found for this property.");
      }
    } catch (err) {
      alert("Error fetching rules:", err);
    }
  };

  const isDateDisabled = (date) => {
    return disabledDates.some(
      (disabledDate) =>
        disabledDate.getFullYear() === date.getFullYear() &&
        disabledDate.getMonth() === date.getMonth() &&
        disabledDate.getDate() === date.getDate()
    );
  };

  const onDateSelected = (dates) => {
    if (!dates || dates.length !== 2) {
      setEnableButton(false);
      alert('Invalid date range');
      return;
    }
    const [startDate, endDate] = dates;
    const allDates = getDatesInRange(startDate, endDate);
    setSelectedDates(allDates);
    updateBooking({
      property: property,
      selectedDates: allDates
    });
    setEnableButton(true);
  };

  const verifySelectedDates = () => {
    const minNights = rules[0];
    const maxNights = rules[1];

    if (selectedDates.length > minNights && selectedDates.length < maxNights) {
      if (!checkForInvalidSelections()) {
        setRulesApproved(true);
      } else {
        setRulesApproved(false);
        alert('Some Dates are not longer available');
      }
    } else {
      setRulesApproved(false);
      alert(`Select Between ${minNights} and ${maxNights} nights`);
    }
  };

  const checkForInvalidSelections = () => {
    const formattedDates = disabledDates.map(formatDate);
    const formattedSet = new Set(formattedDates);
    return selectedDates.some((date) => formattedSet.has(date));
  };

  const toggleAmenities = () => {
    setShowAllAmenities(!showAllAmenities);
  };

  if (!property) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto p-0">
      <div className="grid grid-cols-1 lg:grid-cols-[70%_30%] gap-0 lg:gap-8">
        <div className="overflow-y-auto p-4 lg:border-r border-gray-300 pb-[100px] lg:max-h-[1000px]">
          <ImgGallery property={property} />
          <h2 className="text-[25px] text-blue text-left font-semibold mt-[30px]">
            {property.title}
          </h2>
          <p className="text-left text-gray-600 text-[15px] mt-[10px]">
            <i className="bi bi-geo-alt"></i> {property.address.city}, {property.address.state}
          </p>
          <p className="text-left mt-[40px]">{property.publicDescription?.summary || ""}</p>

          <div className="flex justify-left text-gray-700 gap-2 my-[50px]">
            <div className="flex items-center gap-[10px] border border-gray-200 px-2 py-1 rounded">
              <FaUsers className="text-blue text-[25px]" />
              <p className="text-sm">
                {property.accommodates} <br /> people
              </p>
            </div>
            <div className="flex items-center gap-[10px] border border-gray-200 px-2 py-1 rounded">
              <FaDoorOpen className="text-blue text-[25px]" />
              <p className="text-sm">
                {property.bedrooms} <br /> bedrooms
              </p>
            </div>
            <div className="flex items-center gap-[10px] border border-gray-200 px-2 py-1 rounded">
              <FaToilet className="text-blue text-[25px]" />
              <p className="text-sm">
                {property.bathrooms} <br /> bathrooms
              </p>
            </div>
          </div>

          <Map title={property.nickname} lat={property.address.lat} long={property.address.lng} />

          <h2 className="text-left text-gray-500 text-[30px] mt-[30px] ml-[10px]"> Amenities </h2>
          <div className={`relative ${showAllAmenities ? 'max-h-auto' : 'max-h-[300px]'} overflow-y-hidden grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-[30px] w-[99%] ml-auto mr-0`}>
            {property.amenities.map((amenity, index) => (
              <div key={index} className="text-left flex gap-[20px] mb-[10px] items-center text-[15px]">
                <i className="bi bi-check-circle-fill text-blue"></i> {amenity}
              </div>
            ))}
            {!showAllAmenities && (
              <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
            )}
          </div>
          {property.amenities.length > 8 && (
            <button
              onClick={toggleAmenities}
              className="mt-4 text-blue hover:text-blue-800"
            >
              {showAllAmenities ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>

        <div className="-mt-[50px] mb-[150px] md:mt-5 w-full">
          <h2 className="text-center text-gray-500 text-[30px] w-[90%] mx-auto font-medium"> Availability </h2>
          {!calendarLoading && (
            <Calendar
              selectRange={true}
              onChange={onDateSelected}
              minDate={new Date()}
              tileDisabled={({ date }) => isDateDisabled(date)}
              tileContent={({ date }) => {
                if (!availableDates) return null;

                const availableDate = availableDates.find(
                  (item) =>
                    item.date.getFullYear() === date.getFullYear() &&
                    item.date.getMonth() === date.getMonth() &&
                    item.date.getDate() === date.getDate()
                );

                return availableDate ? (
                  <span className="text-[10px]">${availableDate.price}</span>
                ) : null;
              }}
            />
          )}
          {enableButton && rulesApproved ? (
            <button
              className="bg-blue text-white mx-auto py-3 rounded-full w-[80%] mt-4"
              onClick={() => setShowPopup(true)}
            >
              <i className="bi bi-calendar2-check mr-[10px]"></i> Book Now
            </button>
          ) : (
            <p className="text-center text-gray-400 text-[15px] w-[95%] mx-auto">
              Select your desired stay dates to proceed with booking. Once you've picked your dates, you'll be all set to confirm your reservation!
            </p>
          )}
        </div>
      </div>
      {showPopup && (
        <BookingPopup
          property={property}
          closePopup={() => setShowPopup(false)}
          selectedDates={selectedDates}
          calendar={availableDates}
        />
      )}
    </div>
  );
}

export default PropertyDetails;
