import React, { useState } from 'react';

function ImgGallery({ property }) {
  const [imgToShow, setImgToShow] = useState(1);

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-2">
        {/* Main Picture */}
        <div className="w-full h-[300px] md:h-[500px] overflow-hidden rounded-lg">
          <img
            id="mainPicture"
            src={property.pictures[imgToShow]?.original}
            alt="Main"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        {/* Gallery Thumbnails */}
        <div className="flex md:flex-col gap-2 overflow-x-auto md:overflow-y-auto md:max-h-[500px] overflow-y-scroll">
          {property.pictures?.map((picture, index) => (
            <img
              key={index}
              src={picture.original}
              alt={`Thumbnail ${index}`}
              onMouseOver={() => setImgToShow(index)}
              className="w-24 h-24 object-cover rounded-lg cursor-pointer border hover:border-blue-500"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImgGallery;
