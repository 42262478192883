import React, { useContext, useState, useEffect } from "react";
import { PropertiesContext } from "../context/Properties";

function Hero() {
  const { properties } = useContext(PropertiesContext);
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
  const [selectedPictureIndex, setSelectedPictureIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (properties.length > 0) {
      setLoading(false);
    }
  }, [properties]);

  useEffect(() => {
    if (properties.length > 0 && !loading) {
      const intervalId = setInterval(() => {
        handleNext();
      }, 4000);
      return () => clearInterval(intervalId);
    }
  });

  if (loading) {
    return (
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-[#0089BF] z-[999]">
        <img
          src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1683933526/Final_Logo_1_byrdtx_m9colt.png"
          alt="Loading"
          className="mb-5 w-3/4 max-w-xs animate-fadeIn"
        />
        <div className="loader"></div>
      </div>
    );
  }

  const currentProperty = properties[currentPropertyIndex];

  const handleNext = () => {
    setCurrentPropertyIndex((prevIndex) =>
      prevIndex === properties.length - 1 ? 0 : prevIndex + 1
    );
    setSelectedPictureIndex(1);
  };

  const handleThumbnailClick = (index) => {
    setSelectedPictureIndex(index);
  };

  return (
    <div className="w-[95%] mt-3  md:w-4/5 h-[50vh] md:h-[70vh] max-h-[700px] mx-auto my-10 rounded-2xl transition-all duration-1000">
      {currentProperty && currentProperty.pictures[selectedPictureIndex] && (
        <div
          className="relative bg-cover bg-center h-full rounded-2xl transition-all duration-1000 ease-in-out"
          style={{
            backgroundImage: `url(${currentProperty.pictures[selectedPictureIndex].original})`,
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-between p-4 md:p-8 bg-black bg-opacity-30 rounded-2xl">
            <div className="text-white">
              <h2 className="text-left max-w-full md:max-w-[60%] text-3xl md:text-5xl font-semibold text-shadow mb-0">
                {currentProperty.title}
              </h2>
              <p className="flex items-center text-left text-lg md:text-2xl font-medium text-shadow mt-2">
                <i className="bi bi-geo-alt mr-2"></i>
                {currentProperty.address.city}, {currentProperty.address.state}
              </p>
            </div>
            <div className="flex justify-center md:justify-end md:items-start md:absolute md:top-4 md:right-4 md:flex-col space-x-2 md:space-x-0 md:space-y-2 mt-4 md:mt-0 mx-auto md:mx-0">
              {currentProperty.pictures.slice(1, 5).map((picture, index) => (
                <img
                  className="w-16 h-16 md:w-24 md:h-24 object-cover border-4 border-white rounded-lg cursor-pointer transform hover:scale-110 transition-transform"
                  key={index}
                  src={picture.original}
                  alt={`Thumbnail ${index + 2}`}
                  onClick={() => handleThumbnailClick(index + 1)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero;
