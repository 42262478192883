import React from 'react';
import { FaDoorOpen, FaToilet, FaUsers } from 'react-icons/fa';

function PropertyCard({ property, index }) {
  return (
    <div className="border rounded-lg shadow-lg p-4 space-y-4 w-full max-w-[400px] mx-auto">
      <div className="w-full h-52">
        <img
          src={property.pictures[0].original}
          alt={property.title}
          className="w-full h-full object-cover rounded-md"
        />
      </div>

      <div className="flex items-center h-[60px]">
        <p className="text-[22px] font-bold text-gray-800 text-left">
          {property.title}
        </p>
      </div>
      
      <p className="text-gray-600 flex items-center">
        <i className="bi bi-geo-alt mr-2"></i>
        {property.address.city}, {property.address.state}
      </p>

      <div className="grid grid-cols-3 w-[100%] mx-auto gap-[10%] py-4">
        <div className='border border-gray-200 rounded p-2'> 
          <div className='flex items-center justify-between flex-row-reverse w-[60%] mx-auto'>
            <FaUsers className="text-blue"/>  
            <p className="text-[13px] text-gray-500">{property.accommodates}</p> 
          </div>
          <p className='text-gray-500 text-[13px] mx-auto w-fit'> people </p>
        </div>
        <div className='border border-gray-200 rounded p-2'> 
          <div className='flex items-center justify-between flex-row-reverse w-[60%] mx-auto'>
            <FaDoorOpen className="text-blue"/>  
            <p className="text-[13px] text-gray-500">{property.bedrooms}</p> 
          </div>
          <p className='text-center text-gray-500 text-[13px] mx-auto'> bedrooms </p>
        </div>
        <div className='border border-gray-200 rounded p-2'> 
          <div className='flex items-center justify-between flex-row-reverse w-[60%] mx-auto'>
            <FaToilet className="text-blue"/>  
            <p className="text-[13px] text-gray-500">{property.bathrooms}</p> 
          </div>
          <p className='text-center text-gray-500 text-[13px] mx-auto'> bathrooms </p>
        </div>
      </div>

      <a href={`/property/${property.nickname}`}>
        <button className="w-full bg-blue border border-blue text-white py-2 rounded-md hover:bg-white hover:text-blue transition mt-4">
          Read More
        </button>
      </a>
    </div>
  );
}

export default PropertyCard;
