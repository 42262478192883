import React from 'react'

function Footer() {
  return (
    <div className="bg-[#072A40] text-white py-8 px-4 md:px-8 flex flex-col md:flex-row justify-between gap-8">
      <div className="flex flex-col items-center md:items-start">
        <img 
          src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1683933526/Final_Logo_1_byrdtx_m9colt.png" 
          alt="Better Stays Logo"
          className="h-[40px] md:h-16 mb-3 object-contain"
        />
        <a href='tel:+18152009715' className="text-gray-300 hover:text-white mb-1"> +1 (815) 200 9715 </a>
        <a href='mailto:betterstaynow@gmail.com' className="text-gray-300 hover:text-white mb-3"> betterstaynow@gmail.com </a>
        
        <p className="w-4/5 mx-auto text-center md:w-1/2 md:text-left md:mx-0">
          Better Stays is the Midwest's premier vacation rental company. Don't just stay anywhere, stay better!
        </p>
      </div>

      <div className="flex flex-col justify-between items-center md:items-end">
        <div className="flex max-w-md mb-2">
          <input 
            type='text' 
            placeholder='email@example.com' 
            className="px-4 py-2 border border-gray-300 rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="bg-blue hover:bg-blue-700 text-white px-4 py-2 rounded-r transition-colors"> 
            Submit 
          </button>
        </div>
        
        <p className="mt-4 text-sm text-gray-400 mb-6 max-w-md text-center md:text-right"> 
          *Get the latest information and deals by just signing up your email with us. No Spam Btw. 
        </p>
        
        <div className="flex flex-wrap justify-center md:justify-end gap-4">
          <a href='/' className="text-gray-300 hover:text-white"> Home </a>
          <a href='/rentals' className="text-gray-300 hover:text-white"> Rentals </a>
          <a href='/services' className="text-gray-300 hover:text-white"> Services </a>
          <a href='/contact' className="text-gray-300 hover:text-white"> Contact </a>
          {/* <a href='/login' className="text-gray-300 hover:text-white"> Login </a> */}
          <a href='/terms-and-conditions' className="text-gray-300 hover:text-white"> T & C </a>
          {/* <a href='https://dashboard-better-stays.vercel.app/' className="text-gray-300 hover:text-white"> Access </a> */}
        </div>
      </div>
    </div>
  )
}

export default Footer